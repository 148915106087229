<script lang="tsx" setup>
import { openCannyPage } from 'core'
import { useSidebarState } from './shared'

const { user, isActive, planName, toggleMenu, logout } = useSidebarState()

const pages = {
  main: [
    { icon: 'solar:soundwave-bold', name: 'Recordings', path: '/' },
    { icon: 'solar:settings-bold', name: 'Settings', path: '/settings' }
  ],

  secondary: [
    { icon: 'solar:link-circle-bold', name: 'Integrations', path: '/integrations' },
    { icon: 'solar:record-minimalistic-bold', name: 'Collect', path: '/settings/collect' },
    { icon: 'solar:chat-round-call-bold', name: 'Contact Form', path: '/settings/form' }
  ]
}
</script>

<template>
  <div class="z-100">
    <!-- Backdrop Overlay -->
    <Transition name="fade">
      <div
        v-if="isActive" class="md:hidden absolute left-0 top-0 w-screen h-screen bg-utility-overlay"
        @click="toggleMenu(false)"
      />
    </Transition>

    <nav :class="[$style.sidebar, isActive && $style.visible]">
      <div class="gap-2 flex items-center">
        <img
          class="flex-shrink-0" src="@assets/images/branding/vocal-identity-label-logo-white.svg" alt="" width="71.5"
          height="24"
        >
        <RouterLink
          to="/settings#subscription"
          class="p-1px px-2 gap-1 flex items-center bg-neutral-100 text-sm text-primary-500 font-semibold rounded-xl"
        >
          <Icon v-if="user.subscribed" name="solar:crown-bold-duotone" />
          {{ planName }}
        </RouterLink>
      </div>

      <ul :class="$style.links">
        <template v-for="group, key in pages" :key="key">
          <RouterLink
            v-for="route, index in group" :key="route.path" :to="route.path" :class="[
              $style.link_item, {
                'text-sm': key === 'secondary',
                'mt-10': index === 0 && key === 'secondary',
              },
            ]"
          >
            <Icon :name="route.icon" />
            {{ route.name }}
          </RouterLink>
        </template>
      </ul>

      <LazySidebarUpgradeNudge />

      <section :class="$style.meta">
        <Popover :modal-class="$style.profile_menu" overlay-class="bg-utility-overlay/20">
          <div
            class="outline-2 outline-offset-2 outline-solid outline-transparent hover:outline-neutral-50/50 transition-colors cursor-pointer rounded-full"
          >
            <Avatar :src="user.avatarUrl" size="32" class="pointer-events-none" />
          </div>

          <template #content>
            <a @click="openCannyPage(user.$state)">
              <Icon name="solar:lightbulb-bolt-bold" /> Feature Requests
            </a>

            <a @click="logout">
              <Icon name="solar:logout-linear" /> Logout
            </a>
          </template>
        </Popover>

        <div class="grid [&>*]:text-ellipsis [&>*]:overflow-hidden [&>*]:hover:break-all">
          <h4 class="text-paragraph-3 text-primary-100 font-medium">
            {{ user.name || 'You' }}
          </h4>
          <span class="text-paragraph-4 text-primary-300">{{ user.email }}</span>
        </div>
      </section>
    </nav>
  </div>
</template>

<style module lang="sass">
.sidebar
  @apply gap-4 p-6 w-240px h-full flex flex-col flex-shrink-0 overflow-y-auto
  @apply bg-primary-500 bg-no-repeat

  background-image: url(@assets/images/elements/soundwave-blue.svg)
  background-size: 512px 180px
  background-position: -220px 0px

  @screen lt-md
    @apply fixed left-0 top-0 bottom-0

    transform: translateX(-100%)
    transition: transform 200ms ease

    &.visible
      transform: translateX(0px)

.links
  @apply mt-20 mb-auto h-max grid grid-cols-1

  .link_item
    @apply gap-3 flex items-center text-primary-300 hover:text-primary-100
    @apply hover:bg-white/10 -mx-6 px-6 py-2

    &:global(.router-link-active)
      @apply text-shade-light font-medium bg-neutral-50 text-primary-500

.meta
  @apply gap-2 grid items-center justify-between
  grid-template: 42px / 32px 1fr

.profile_menu
  @apply bottom-0 left-0

  // List items
  & > li, & > a
    @apply py-3 px-2 gap-2 flex items-center text-sm text-neutral-600 cursor-pointer
    @apply rounded-md hover:bg-primary-100 hover:text-primary-500

  svg
    @apply text-xl opacity-60
</style>
